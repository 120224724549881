<template>
  <div class="AppDatePicker">
    <date-picker
      :range="range"
      :shortcuts="shortcuts"
      v-model="model"
      :clearable="false">
      <template #input="{ props, events }">
        <v-text-field
          :label="label"
          outlined
          dense
          :hide-details="true"
          v-bind="props"
          v-on="events"></v-text-field>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import '@/sass/datepicker.scss';

export default {
  name: 'AppDatePicker',
  components: {
    DatePicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    range: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, Date, String],
      validator: (value) => {
        if (Array.isArray(value)) {
          return value.every((v) => {
            const date = new Date(v);
            return !isNaN(date.valueOf());
          });
        }

        if (typeof value === 'string') {
          const date = new Date(value);
          return !isNaN(date.valueOf());
        }

        return value instanceof Date && !isNaN(value.valueOf());
      },
    },
  },
  computed: {
    shortcuts() {
      if (this.range) {
        return [
          {
            text: 'Today',
            onClick: () => [new Date(), new Date()],
          },
          {
            text: 'Yesterday',
            onClick: () => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              return [date, date];
            },
          },
          {
            text: 'Last 7 Days',
            onClick: () => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              return [date, new Date()];
            },
          },
          {
            text: 'Last 30 Days',
            onClick: () => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              return [date, new Date()];
            },
          },
          {
            text: 'Last 3 month',
            onClick: () => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 91);
              return [date, new Date()];
            },
          },
          {
            text: 'Last year',
            onClick: () => {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365);
              return [date, new Date()];
            },
          },
        ];
      }

      return [];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import './app-date-picker.scss';
</style>
