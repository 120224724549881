<template>
  <div class="ActivityWorkflow">
    <Loader v-if="loading" />
    <div
      v-if="!Object.keys(rows).length"
      align="center">
      No Failed Workflows found
    </div>
    <div v-else>
      <div
        v-for="(row, index) in rows"
        :key="index">
        <h3 class="mt-5">
          {{ `Customer ${index}` }}
        </h3>
        <v-data-table
          class="clickable"
          :items="row"
          :headers="headers"
          item-key="id"
          @click:row="goToHistoryPage"
          :loading="loading">
          <template v-slot:item.start="{ item }">
            <span>{{ formatDate(item.start) }}</span>
          </template>
          <template v-slot:item.finish="{ item }">
            <span>{{ formatDate(item.finish) }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import Loader from '@/components/loader/loader.vue';
import {createNamespacedHelpers} from "vuex";

const {
    mapMutations: workflowHistoryMutation,
} = createNamespacedHelpers('workflows/history');

const {
    mapMutations: customerMutations,
    mapGetters: customerGetters,
} = createNamespacedHelpers('customer');

export default {
  name: 'FailedWorkflows',
  components: {
    Loader,
  },
  computed: {
      ...customerGetters({
            customers: 'CUSTOMERS'
      }),
  },
  props: {
    rows: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    statsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Workflow name',
          value: 'AutomationWorkflow.name',
          sortable: false,
          align: 'start',
        },
        { text: 'Start', value: 'start' },
        { text: 'Finish', value: 'finish' },
        { text: 'Status', value: 'workflow_status' },
        { text: 'Total tasks', value: 'stats.tasks_total' },
        { text: 'Task errors', value: 'stats.tasks_failed' },
        { text: 'Successfull tasks', value: 'stats.tasks_success' },
      ],
    };
  },
  methods: {
      ...workflowHistoryMutation({
          setWorkflowHistoryItem: 'SET_WORKFLOW_HISTORY_ITEM'
      }),
      ...customerMutations({
          setCustomer: 'SET_SELECTED_CUSTOMER'
      }),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz('UTC').format('YYYY-MM-DD HH:mm');
    },
      goToHistoryPage(item) {
          const customer = this.customers.find((cstr) => cstr.customer_id === item.customer_id)
          this.setCustomer(customer)
          this.setWorkflowHistoryItem(item);
          this.$router.push({
              path: `/workflows/${item.workflow_id}/history?customer_id=${item.customer_id}`,
          });
      },
  },
};
</script>
<style lang="scss" scoped>
//clicable row
.clickable {
  cursor: pointer;
}
</style>
