<template>
  <div class="ActivityTransaction">
    <v-data-table
      class="ActivityTransaction__data-table elevation-0"
      :items="rows"
      :headers="headers"
      single-expand
      :expanded.sync="expanded"
      item-key="ref_number"
      show-expand
      :loading="loading"
    >
      <template v-slot:item.transaction_date="{ item }">
        <span>{{ formatDate(item.transaction_date) }}</span>
      </template>
      <template v-slot:item.data_payment_id="{ item }">
        <v-icon v-if="item.data_payment_id" color="green">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else color="red"> mdi-cancel </v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card tile flat>
            <v-card-title>Transaction #{{ item.ref_number }}</v-card-title>
            <v-card-subtitle>Transaction summary</v-card-subtitle>
            <v-card-text>
              <p>Patient #{{ item.Patient ? item.Patient.patient_id : "-" }}</p>
              <p>
                Patient name:
                {{
                  item.Patient
                    ? `${item.Patient.lastname}, ${item.Patient.firstname}`
                    : "-"
                }}
              </p>
              <p>Amount: {{ item.amount }}</p>
              <p>Payment type: {{ item.paymentType }}</p>
              <p>ID received on payment post: {{ item.data_payment_id }}</p>
              <p>Card last four: {{ item.Card ? item.Card.lastfour : "-" }}</p>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';

export default {
  name: 'ActivityTransaction',
  props: {
    rows: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Transaction date",
          align: "start",
          sortable: true,
          value: "transaction_date",
        },
        { text: "Patient ID", value: "Patient.patient_id" },
        { text: "Status", value: "status" },
        { text: "Posted", value: "data_payment_id" }
      ],
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz('UTC').format('YYYY-MM-DD HH:mm');
    },
  }
}
</script>
