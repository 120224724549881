<template>
  <div class="ActivityWorkflow">
    <Loader v-if="loading" />
    <div v-if="!Object.keys(rows).length" align="center">
      No Running Workflows found
    </div>
    <div v-else>
      <div v-for="(row, index) in rows" :key="index">
        <h3 class="mt-5">
          {{ `Customer ${index}` }}
        </h3>
        <v-data-table
          class="ActivityWorkflow__data-table elevation-0"
          :items="row"
          :headers="headers"
          item-key="id"
          :loading="loading"
          @click:row="goToHistoryPage"
        >
          <template v-slot:item.start="{ item }">
            <span>{{ formatDate(item.start) }}</span>
          </template>
            <template v-slot:item.actions="{ item }">
                <v-btn color="primary" outlined small style="margin-right: 4px" @click.stop="stopAndDeleteWorkflow({customer_id: item.customer_id, storageId: item.id, workflow_id: item.workflow_id, signal: `STOP`})">
                    STOP
                </v-btn>
                <v-btn color="primary" outlined small @click.stop="stopAndDeleteWorkflow({customer_id: item.customer_id, storageId: item.id, workflow_id: item.workflow_id, signal: `PAUSE`})">
                    PAUSE
                </v-btn>
            </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import Loader from '@/components/loader/loader.vue';
import {createNamespacedHelpers} from "vuex";

const {
    mapActions: workflowHistoryActions,
    mapMutations: workflowHistoryMutation,
} = createNamespacedHelpers('workflows/history');

const {
    mapMutations: customerMutations,
    mapGetters: customerGetters,
} = createNamespacedHelpers('customer');

export default {
  name: 'RunningWorkflows',
  components: {
    Loader,
  },
  computed: {
    ...customerGetters({
        customers: 'CUSTOMERS'
    }),
  },
  props: {
    rows: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    statsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Workflow name',
          value: 'AutomationWorkflow.name',
          sortable: false,
          align: 'start',
        },
        { text: 'Start', value: 'start' },
        { text: 'Status', value: 'workflow_status' },
        { text: 'Actions', value: 'actions', key: 'actions', sortable: false }
      ],
    };
  },
  methods: {
      ...workflowHistoryActions([
          'stopWorkflow',
      ]),
      ...workflowHistoryMutation({
          setWorkflowHistoryItem: 'SET_WORKFLOW_HISTORY_ITEM'
      }),
      ...customerMutations({
          setCustomer: 'SET_SELECTED_CUSTOMER'
      }),
    formatDate(date) {
      if (!date) return '';
      else
        return moment(date)
          .tz('UTC')
          .format('YYYY-MM-DD HH:mm');
    },
      deleteItem(customer_id, id) {
          this.rows[customer_id] = this.rows[customer_id].filter((row) => row.id !== id);
      },
      stopAndDeleteWorkflow(data) {
          this.stopWorkflow(data).then((_, err) => { if(!err) { this.deleteItem(data.customer_id, data.storageId) } })
      },
      goToHistoryPage(item) {
          const customer = this.customers.find((cstr) => cstr.customer_id === item.customer_id)
          this.setCustomer(customer)
          this.setWorkflowHistoryItem(item);
          this.$router.push({
              path: `/workflows/${item.workflow_id}/history?customer_id=${item.customer_id}`,
          });
      },
  },
};
</script>
